$(document).ready(function(){
  if($('.timeline').length > 0) {
		// const mouseWheel = document.querySelector('.timeline-scroll');
		// mouseWheel.addEventListener('wheel', function (e) {
		//   const race = 15; // How many pixels to scroll

		//   if (e.deltaY > 0)
		//     // Scroll right
		//     mouseWheel.scrollLeft += race;
		//   // Scroll left
		//   else mouseWheel.scrollLeft -= race;
		//   e.preventDefault();
		// });

		// $('.next-section').click(function(e){
		//   e.preventDefault();
		//   var next = $(this).parent().next();
		//   $('html, body').stop(true, false).animate({
		//     scrollTop: next.offset().top,
		//   }, 1200);
		// });
		// $('.prev-section').click(function(e){
		//   e.preventDefault();
		//   var prev = $(this).parent().prev();
		//   $('html, body').stop(true, false).animate({
		//     scrollTop: prev.offset().top,
		//   }, 1200);
		// });

		// refactor horizontal scroll to use arrows
		var scrolling = false;

		$('.arrow').on('mousedown',function (evt) {
			scrolling = true;
			startScrolling($('.timeline-scroll'), 100, evt.target.id);
		});

		$(document).on('mouseup', function () {
			scrolling = false;
		});

    

		function startScrolling(obj, spd, btn) {
			var travel = btn.indexOf('left') > -1 ? '-=' + spd + 'px' : '+=' + spd + 'px';
			if (!scrolling) {
				obj.stop();
			} else {
        obj.animate({
          scrollLeft: travel,
        }, 'fast', 'linear', function () {
          startScrolling(obj, spd, btn);
        });
			}
		}
	}
});


